import { Heading, Label } from '@loveholidays/design-system';
import { TFunction, useTranslation } from '@loveholidays/phrasebook';
import dayjs from 'dayjs';
import React from 'react';

import { CombinedHotelImage } from './CombinedHotelImage/CombinedHotelImage';
import { type FavouritesList as FavouritesListType } from '@AuroraTypes';
import { MONTH_FLEXIBILITY_VALUE } from '@Constants/Flexibility';
import { Card } from '@DesignSystemComponents/Card/Card';
import { getSearchSelectionFromUrl } from '@Stores/SearchSelectionStore/getSearchSelectionFromUrl';

export const MOBILE_SIZE = 64;
export const DESKTOP_SIZE = 230;

const formatSearch = (search: FavouritesListType['searchQuery'], t: TFunction) => {
  const { date, flexibility, nights, rooms = [] } = getSearchSelectionFromUrl(search);
  const parts = [];

  // -- Date
  if (date) {
    parts.push(
      dayjs
        .utc(date)
        .format(
          flexibility === MONTH_FLEXIBILITY_VALUE
            ? t('calendarMonthYearFormat')
            : t('calendarDateFormatShort'),
        ),
    );
  } else {
    parts.push(t('anytime'));
  }

  // -- Rooms
  const partySize = rooms.reduce((sum, room) => sum + room.adults + room.childAges.length, 0);
  parts.push(t('rooms.labels.room', { count: rooms.length }));
  parts.push(t('rooms.labels.guest', { count: partySize }));

  // -- Nights
  parts.push(t('rooms.labels.nights', { count: nights }));

  return parts.join(', ');
};

export interface FavouritesListProps {
  list: FavouritesListType;
  compact?: boolean;
}

export const FavouritesList: React.FC<FavouritesListProps> = ({
  list: { name, masterIds, searchQuery },
  compact = false,
}) => {
  const { t } = useTranslation();

  return (
    <Card
      variant={compact ? 'SmallWide' : 'ResponsiveTallToWide'}
      floating
      images={[]}
      imageComponent={
        <CombinedHotelImage
          masterIds={masterIds}
          height={compact ? MOBILE_SIZE : [MOBILE_SIZE, DESKTOP_SIZE, DESKTOP_SIZE]}
          width={compact ? MOBILE_SIZE : [MOBILE_SIZE, DESKTOP_SIZE, DESKTOP_SIZE]}
          compact={compact}
        />
      }
      height={compact ? MOBILE_SIZE : [MOBILE_SIZE, DESKTOP_SIZE, DESKTOP_SIZE]}
      sx={{
        width: '100%',
        '> div:first-of-type': {
          flex: `0 0 ${MOBILE_SIZE}px`,
        },
        '> div:nth-of-type(2)': {
          paddingY: [0, 'xs'],
        },
        ...(compact && {
          '> div:first-of-type': {
            flex: `0 0 ${MOBILE_SIZE}px`,
          },
          '> div:nth-of-type(2)': {
            paddingY: 0,
          },
        }),
      }}
    >
      <Heading
        as="h3"
        variant={compact ? 'smallbold' : ['smallbold', 'mediumbold']}
        sx={{
          marginBottom: compact ? '5xs' : ['5xs', '3xs'],
          wordBreak: 'break-word',
        }}
      >
        {name}
      </Heading>
      <Label
        variant="small"
        sx={{
          display: 'block',
          color: 'iconDimmedmedium',
          marginBottom: compact ? '4xs' : ['4xs', '3xs'],
        }}
      >
        {formatSearch(searchQuery, t)}
      </Label>
      <Label
        variant="small"
        sx={{
          display: 'block',
          color: 'iconDimmedmedium',
        }}
      >
        {masterIds.length
          ? t('favourites.xFavouriteHolidays', { count: masterIds.length })
          : t('favourites.noHolidaysSaved')}
      </Label>
    </Card>
  );
};
